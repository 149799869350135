import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerLink,
      modularBlocks,
      contactFormType,
    },
  },
}) => (
  <Layout contactFormType={contactFormType}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={bannerText}
        link={bannerLink}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...SubPageBannerImageFragment
      }
      bannerText
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ActivitiesListingModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentFeaturedLogosModularBlockFragment
        ...ContentFeaturedPointsModularBlockFragment
        ...ContentModularBlockFragment
        ...DonationsModularBlockFragment
        ...DonationCtaModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedAccommodationModularBlockFragment
        ...FeaturedActivitiesModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedStatisticsModularBlockFragment
        ...FileDownloadsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageCtaModularBlockFragment
        ...ImageCardsModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImagesContentModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...LatestBlogPostsModularBlockFragment
        ...LogoContentBlocksModularBlockFragment
        ...LondonMarathonRegistrationFormModularBlockFragment
        ...PackagesModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamListingModularBlockFragment
        ...TeamProfileModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
      contactFormType
    }
  }
`;

export default DefaultPageTemplate;
